.exit {
  display: inline;
  margin-right: 15px;
}
.exit-icon {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.664);
}
.exit-icon:hover {
  cursor: pointer;
  color: rgba(197, 37, 37, 0.61);
  transition: 0.3s;
}
