@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oswald:wght@200&family=Saira+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oswald:wght@200&family=Saira+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020c1d;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23061126'/%3E%3Cstop offset='1' stop-color='%23061126' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23021d31'/%3E%3Cstop offset='1' stop-color='%23021d31' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23072239'/%3E%3Cstop offset='1' stop-color='%23072239' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23020C1D'/%3E%3Cstop offset='1' stop-color='%23020C1D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230D162D'/%3E%3Cstop offset='1' stop-color='%230D162D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003846'/%3E%3Cstop offset='1' stop-color='%23003846' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App_App__16ZpL {
  text-align: center;
  position: relative;
  min-height: 96vh;
  margin: 0;
  overflow: hidden;
}

.MainWeatherCard_mainCard__m2KWU {
  text-align: center;
  margin-top: 100px;
  background-color: rgba(54, 54, 54, 0.342);
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  height: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-bottom: 3rem;
}
.MainWeatherCard_MaincardEmpty__3OUhM {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: "400";
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.MainWeatherCard_switch__VdTYc {
  text-align: left;
  margin-left: 1rem;
  margin-top: 1rem;
  position: absolute;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.MainWeatherCard_switch__VdTYc p {
  display: inline;
  color: rgba(224, 223, 217, 0.603);
  vertical-align: top;
  font-family: "Architects Daughter", cursive;
  font-family: "Oswald", sans-serif;
  font-family: "Saira Condensed", sans-serif;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (min-width: 1900px) {
  .MainWeatherCard_switch__VdTYc {
    text-align: left;
    margin-left: -7%;
    margin-top: 1rem;
    position: absolute;
  }

  .MainWeatherCard_switch__VdTYc p {
    display: inline;
    color: rgba(224, 223, 217, 0.603);
    vertical-align: top;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.WeatherCard_cardBackground__T8C3_ {
  text-align: right;
  background-color: rgba(204, 200, 196, 0.103);
  height: 350px;
  width: 80%;
  margin: 10px;
  border-radius: 20px;
  color: whitesmoke;
  font-family: "Josefin Sans", sans-serif;
  font-size: 17pt;
  transition: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}
.WeatherCard_cardBackgroundvalid__3PXFS {
  text-align: right;
  background-color: rgba(163, 104, 45, 0.103);
  height: 450px;
  width: 150%;
  margin: 10px;
  border-radius: 20px;
  color: whitesmoke;
  font-family: "Josefin Sans", sans-serif;
  font-size: 17pt;
  transition: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.WeatherCard_cardBackground__T8C3_:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.WeatherCard_breakLine__EU8-W {
  margin: 10 10;
  color: whitesmoke;
}
.WeatherCard_city-description__1WsIC {
  font-size: 15px;
  margin-top: 20px;
}
.WeatherCard_desc__3WYmF p {
  display: inline;
  font-family: "Architects Daughter", cursive;
}
img {
  -webkit-filter: contrast(1.5);
          filter: contrast(1.5);
}
.WeatherCard_exit__1We7- {
  display: inline;
  margin-right: 15px;
}
.WeatherCard_exit-icon__y_a9u {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.664);
}
.WeatherCard_exit-icon__y_a9u:hover {
  cursor: pointer;
  color: rgba(197, 37, 37, 0.61);
  transition: 0.3s;
}
.WeatherCard_center__1HJxT {
  text-align: center;
  margin-top: -40px;
}

.Exit_exit__DJ_aJ {
  display: inline;
  margin-right: 15px;
}
.Exit_exit-icon__g4geK {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.664);
}
.Exit_exit-icon__g4geK:hover {
  cursor: pointer;
  color: rgba(197, 37, 37, 0.61);
  transition: 0.3s;
}

.Loading_loading-backdrop__2BfPu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(70, 3, 3, 0.055);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

.Loading_loading__pUBux {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  z-index: 100;
  overflow: hidden;
  color: white;
  font-weight: "600";
  background-color: #1091b873;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ToggleSwitch_toggle-switch__1QPlU {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ToggleSwitch_toggle-switch__1QPlU input[type="checkbox"] {
  display: none;
}
.ToggleSwitch_toggle-switch__1QPlU .ToggleSwitch_switch__2K4Wg {
  position: absolute;
  cursor: pointer;
  background-color: rgb(119, 114, 114);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.ToggleSwitch_toggle-switch__1QPlU .ToggleSwitch_switch__2K4Wg::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.ToggleSwitch_toggle-switch__1QPlU input[type="checkbox"]:checked + .ToggleSwitch_switch__2K4Wg::before {
  -webkit-transform: translateX(25px);
          transform: translateX(25px);
  background-color: #0fb8c479;
}
.ToggleSwitch_toggle-switch__1QPlU input[type="checkbox"]:checked + .ToggleSwitch_switch__2K4Wg {
  background-color: #2b57837c;
}

.WeatherSearch_search__1CKon {
  top: 0;
  right: 0;
  position: fixed;
  background-color: rgba(128, 128, 128, 0.623);
  color: white;
  width: 20vmin;
  border-radius: 20px;
  border: transparent;
  margin: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.WeatherSearch_search__1CKon:hover {
  background-color: rgba(67, 67, 75, 0.699);
  cursor: text;
  border: transparent;
}
.WeatherSearch_search__1CKon:focus {
  border-color: transparent;
  border-style: none;
  border: none;
}

.WeatherSearch_srchIcon__1ZyH6 {
  display: inline;
  text-align: left;
  color: rgb(27, 159, 247);
  margin: 0px;
  cursor: pointer;
}

.WeatherSearch_search__1CKon:focus {
  border: transparent;
}

.WeatherSearch_search2__1gWs9 {
  top: 0;
  right: 0;
  position: fixed;
  margin-top: 15px;
  margin-right: 20px;
}

.ErrorMessage_errorBackround__1bR-C {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 100;
  overflow: hidden;
  background-color: #f44336;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ErrorMessage_errorBackround__1bR-C label {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 10px;
}
.ErrorMessage_backdrop__2pafG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(7, 19, 22, 0.815);
}

.ErrorMessage_redo__2W8TZ {
  color: white;
  right: 0;
  display: inline;
  top: 0;
  right: 0;
  position: fixed;
  margin-top: 15px;
  margin-right: 20px;
  transition: 0.8s;
}
.ErrorMessage_redo__2W8TZ:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .ErrorMessage_errorBackround__1bR-C label {
    font-size: 15px;
  }
}

.WeatherForecast_forecastCard__1GMhy {
  overflow: hidden;
  z-index: 100;
  position: absolute;
  text-align: center;
  margin-top: 10rem;
  /*  background-color: rgba(39, 201, 193, 0.151); */
  display: block;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  height: auto;
  border-radius: 30px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 25px;
  background-color: #e6e8eb;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231b3868'/%3E%3Cstop offset='1' stop-color='%231b3868' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23124b67'/%3E%3Cstop offset='1' stop-color='%23124b67' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230d4c74'/%3E%3Cstop offset='1' stop-color='%230d4c74' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23203A5B'/%3E%3Cstop offset='1' stop-color='%23203A5B' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23163475'/%3E%3Cstop offset='1' stop-color='%23163475' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23056772'/%3E%3Cstop offset='1' stop-color='%23056772' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.WeatherForecast_backdrop__7kovx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(70, 3, 3, 0.055);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
.WeatherForecast_x__2SoG4 {
  text-align: right;
}
@media only screen and (max-width: 990px) {
  .WeatherForecast_forecastBackground__N9w-S {
    height: 80%;
  }
  .WeatherForecast_border__2FS49 {
    border-bottom: solid 1px rgba(255, 255, 255, 0.671);
  }
  .WeatherForecast_border__2FS49:last-child {
    border: none;
  }
}

.WeatherForecast_descriptionText__qxawj {
  color: rgba(255, 255, 255, 0.568);
}

.WeatherForecast_bodie__1NAJQ {
  padding: 40px;
}

.WeatherForecast_border__2FS49 {
  border-right: solid 1px rgba(255, 255, 255, 0.671);
}
.WeatherForecast_border__2FS49:last-child {
  border: none;
}

.Author_background__1BBsS {
  background-color: rgba(54, 54, 54, 0.288);
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 4vh;
  padding: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Author_icon__1adLw {
  margin-left: 20px;
  margin-right: 10px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  color: white;
  transition: 0.6s;
}
.Author_text__1FG3M {
  margin-left: 10px;
}
.Author_icon__1adLw:hover {
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
}
.Author_signature__2BJ6M {
  display: inline;
  font-size: 20px;
  font-family: "Architects Daughter", cursive;
  font-family: "Oswald", sans-serif;
  font-family: "Saira Condensed", sans-serif;
}

