.errorBackround {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 100;
  overflow: hidden;
  background-color: #f44336;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.errorBackround label {
  color: rgb(255, 255, 255);
  font-size: 20px;
  margin-top: 10px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(7, 19, 22, 0.815);
}

.redo {
  color: white;
  right: 0;
  display: inline;
  top: 0;
  right: 0;
  position: fixed;
  margin-top: 15px;
  margin-right: 20px;
  transition: 0.8s;
}
.redo:hover {
  transform: scale(1.3);
  transition: 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .errorBackround label {
    font-size: 15px;
  }
}
