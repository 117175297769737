@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oswald:wght@200&family=Saira+Condensed&display=swap");
.mainCard {
  text-align: center;
  margin-top: 100px;
  background-color: rgba(54, 54, 54, 0.342);
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  height: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-bottom: 3rem;
}
.MaincardEmpty {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: "400";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch {
  text-align: left;
  margin-left: 1rem;
  margin-top: 1rem;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch p {
  display: inline;
  color: rgba(224, 223, 217, 0.603);
  vertical-align: top;
  font-family: "Architects Daughter", cursive;
  font-family: "Oswald", sans-serif;
  font-family: "Saira Condensed", sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (min-width: 1900px) {
  .switch {
    text-align: left;
    margin-left: -7%;
    margin-top: 1rem;
    position: absolute;
  }

  .switch p {
    display: inline;
    color: rgba(224, 223, 217, 0.603);
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
