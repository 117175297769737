@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oswald:wght@200&family=Saira+Condensed&display=swap");
.background {
  background-color: rgba(54, 54, 54, 0.288);
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 4vh;
  padding: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon {
  margin-left: 20px;
  margin-right: 10px;
  transform: scale(1.5);
  color: white;
  transition: 0.6s;
}
.text {
  margin-left: 10px;
}
.icon:hover {
  transform: scale(1.6);
}
.signature {
  display: inline;
  font-size: 20px;
  font-family: "Architects Daughter", cursive;
  font-family: "Oswald", sans-serif;
  font-family: "Saira Condensed", sans-serif;
}
